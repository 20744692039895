import React from "react";
import data from "../../data";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import { GlobalState, GlobalStateContext } from "../components/InfiniteScroll/Global";
import Slider from "../components/Reviews/Slider";
import Header from "../components/Header";
import BlogInternal from "../components/AllArticles";
import Loadable from "@loadable/component";
import BackToTop from "../components/BackToTop";
import Link from "../components/Link";
import CaptureForm from "../components/CaptureForm";

import "../assets/sass/Blog.scss";

import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Fade from "react-reveal/Fade";

const Footer = Loadable(() => import("../components/Footer/index.js"));
const components = {};
data.sections.forEach((v) => {
  components[v.component] = Loadable(() =>
    import(`../components/${v.component}`)
  );
});

const  Hero = ({articles}) => {
  
  const data = useStaticQuery(
    graphql`
      query {
        wpPage(isFrontPage: { eq: true }) {
          id
          layout {
 
            bloghero {
              
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                    srcSet
                    src
                    sizes
                    presentationWidth
                    presentationHeight
                    aspectRatio
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  const { bloghero } = data.wpPage.layout;
  
  return (
    <header className={`heroContainer blogHeroContainer`}>
      <BackgroundImage
        Tag="div"
        className={"bgImageFixed bg"}
        backgroundColor={"#afc4c0"}
        style={{
          backgroundPosition: "left top",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundOrigin: "content-box",
        }}
        fluid={bloghero.localFile.childImageSharp.fluid}
      >
        <div className="gradient">
          <div className={`container-fluid h-100`}>
            <div className="row align-items-center h-100">
              <div className="col-md-6" />
              <div className="col-sm-12 col-md-6 align-middle sliderWrapper ">
                <Slider
                  className={`articleContainer`}
                  content={
                    articles &&
                    articles.map((post, i) => (
                      <div className="articlesSummary" key={`blogRev${i}`}>
                        <Fade>
                          <Link className="" to={`/artigos/${post.node.slug}`}>
                            <div className="articleHero">
                              <h3 className="subtitle">{post.node.title}</h3>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: post.node.excerpt,
                                }}
                              ></span>
                            </div>
                          </Link>
                          <div className="text-center pt-2">
                            <Link
                              direction={"left"}
                              to={`/artigos/${post.node.slug}`}
                              className={`btn button-em fg`}
                            >
                              <span>Ler o Artigo</span>
                            </Link>
                          </div>
                        </Fade>
                      </div>
                    ))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </header>
  );
}


const IndexPage = ({ pageContext }) => {
  return (
    <GlobalState>
      <Layout>
        <GlobalStateContext.Consumer>
          {(globalState) => (
            <>
              <SEO />

              <Header selected="blog" frontPage={true} />
              <Hero articles={globalState.page0} />

              <BlogInternal
                globalState={globalState}
                pageContext={pageContext}
              />
              <CaptureForm />
              <BackToTop />
              <Footer />
            </>
          )}
        </GlobalStateContext.Consumer>
      </Layout>
    </GlobalState>
  );
};



export default IndexPage;
