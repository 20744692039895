import React from "react";
import { Helmet } from "react-helmet";
import config from "../../config";
import { useStaticQuery, graphql } from "gatsby";

function SEO({
  data,
  type = `website`,
  image,
  description,
  lang = "Pt",
  meta,
  keywords = [config.keywords],
  title,
  author = config.manifestShortName,
  site = typeof document === "undefined" ? config.url : document.location.href,
}) {
  const dt = useStaticQuery(
    graphql`
      query LogoQuery {
        wp {
          generalSettings {
            title
            description
            language
          }
        }

        logo: file(relativePath: { eq: "img/icon.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  lang = dt.wp.generalSettings.language || lang;
  description = description || dt.wp.generalSettings.description;
  let siteTitle = dt.wp.generalSettings.title;

  if (image) {
    image = image.replace && image.replace(config.url, "");
  } else {
    image = dt.logo.childImageSharp.fluid.src.substr(1);
  }
  if (!site.endsWith("/")) {
    site += "/";
  }
  let jsonD = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: config.url,
    logo: `${config.url}${dt.logo.childImageSharp.fluid.src.substr(1)}`,
    image: [`${config.url}${dt.logo.childImageSharp.fluid.src.substr(1)}`],
    potentialAction: [
      {
        "@type": "SearchAction",
        target: `${config.url}#{search_term_string}`,
        "query-input": "required name=search_term_string",
      },
    ],
    "@id": config.url,
    name: title || siteTitle,
    address: {
      "@type": "PostalAddress",
      streetAddress: "Av. da Republica, n2 7ºAndar",
      addressLocality: "Lisboa",
      addressRegion: "Lisboa",
      postalCode: "1050-191",
      addressCountry: "PT",
    },

    url: config.url,
    telephone: config.tel,
  };
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || " "}
      titleTemplate={title ? `${siteTitle} - %s` : siteTitle}
      meta={[
        {
          name: `author`,
          property: `author`,
          content: author,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `viewport`,
          content: "width=device-width, initial-scale=1.0",
        },
        {
          name: `title`,
          property: `og:title`,
          content: title || siteTitle,
        },
        {
          name: `description`,
          content: description,
        },
        {
          property: `image`,
          content: `${config.url}${image}`,
        },
        {
          name: `description`,
          property: `og:description`,
          content: description,
        },
        {
          name: `type`,
          property: `og:type`,
          content: type,
        },
        {
          property: `og:url`,
          content: site || `${config.url}${config.pathPrefix}`,
        },
        {
          name: `image`,
          property: `og:image`,
          content: `${config.url}${image}`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: description,
        },
        {
          name: `twitter:title`,
          content: title || siteTitle,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `keywords`,
          content: (keywords || []).join(", "),
        },
        { name: "referrer", content: "origin" },
      ]}
    >
      <link rel="canonical" href={site} />
      <script type="application/ld+json">{JSON.stringify(jsonD)}</script>
    </Helmet>
  );
}

export default SEO;
