import React, { useState, useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../../../config";
import "./CaptureForm.scss";

library.add(...[faCheck]);

export default function CaptureForm({
  bg = "primary-bg", 
  title = "Entra para a minha lista exclusiva",
  subtitle = "...e recebe artigos, reflexões e dicas no teu email.",
  cta = "fgBg",
}) {
  const [success, setSuccess] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [listFields, setListfields] = useState({
    //"lista":"5","cliente":"966785","lang":"pt","formid":"7"
  });

  const [message, setMessage] = useState({});

  useEffect(() => {
    message.variant &&
      setTimeout(() => {
        setMessage({});
      }, 10000);
  }, [message]);

  const submitForm = (e) => {
    setClicked(true);
    e.preventDefault();
    if (listFields.email) {
      setMessage({
        msg: `Spam?? No thanks!`,
        variant: "error",
      });
      return;
    }

    fetch(`${config.url}${config.pathPrefix}.netlify/functions/subscribe`, {
      method: "POST",
      body: JSON.stringify(listFields),
    })
      .then((data) => {
        setSuccess(true);
        setClicked(false);
        setListfields({});
        setMessage({
          msg: `Obrigado, terá notícias nossas em breve :)`,
          variant: "success",
        });
      })
      .catch((i) => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
        setMessage({
          msg: `Não estava à espera disto, mas estas coisas às vezes acontecem :) `,
          variant: "error",
        });
      });
  };

  const handleChange = (e) => {
    setListfields({ ...listFields, [e.target.name]: e.target.value });
  };

  return (
    <section className={`contact-section captureFormSection p-5 ${bg}`}>
      <div className="mb-0">
        <h1 className="text-center">{title}</h1>
        {subtitle && <h4 className="text-center">{subtitle}</h4>}
        <form
          method="post"
          className="contact-form p-5"
          onSubmit={(e) => submitForm(e)}
        >
          <input
            type="email"
            name="email"
            id="email"
            onChange={handleChange}
            style={{ display: "none" }}
          />
          <label htmlFor="fname">Nome</label>
          <input
            type="text"
            name="fname"
            id="fname"
            onChange={handleChange}
            required
          />
          <label htmlFor="fmail">Email</label>
          <input
            type="email"
            name="fmail"
            id="fmail"
            onChange={handleChange}
            required
          />

          <button type="submit" className={`btn button-em ${cta} pad`}>
            {clicked ? (
              <>
                <span className="lds-dual-ring" /> A juntar-me...
              </>
            ) : success ? (
              <>
                <FontAwesomeIcon icon="check" className="white" /> Agora
                Pertenço à comunidade
              </>
            ) : (
              <>Juntar-me</>
            )}
          </button>
        </form>
      </div>
      <div className={`snackbar ${message.variant ? message.variant : ""}`}>
        {message.msg}
      </div>
    </section>
  );
}
