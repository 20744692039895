import React from 'react';
import Fade from 'react-reveal/Fade';

import BackgroundImage from 'gatsby-background-image';
import { navigate } from 'gatsby';
import Link from "../Link";

import "./blog.scss";


const PostTile = ({ title, uri, slug, excerpt, featuredImage }) => {
  return (
    <Fade bottom>
      <article
        className="blog-card"
        onClick={(e) =>
          e.target.tagName.toLowerCase() !== "a" && navigate("/artigos/" + slug)
        }
      >
        <div className={`meta horizontal`}>
          <BackgroundImage
            Tag="span"
            fluid={
              featuredImage && 
              featuredImage.node.localFile &&
              featuredImage.node.localFile.childImageSharp.fluid
            }
            className={"photo"}
          />
          <div className="details">
            <Link to={`/artigos/${slug}`}>
              <p
                className="card-text"
                dangerouslySetInnerHTML={{ __html: excerpt }}
              >
              </p>
            </Link>
          </div>
        </div>

        <div className={`description horizontal`}>
          <Link to={`/artigos/${slug}`}>
            <h1 className="card-title">{title}</h1>
          </Link>
        </div>
      </article>
    </Fade>
  );
};

export default PostTile;
