import React from "react";
import { InfiniteScroll } from "./InfiniteScroll/infiniteScroll.js";
import Fade from "react-reveal/Fade";

import PostTile from "./Blog/PostTile.js";

/** View for "home" page with infinite scroll and fallback to pagination. */
class BlogInternal extends React.Component {
  constructor(props) {
    super(props);
    console.log("*** Constructing View ***");
    if (props.globalState.isInitializing()) {
      const pageKey = "page" + props.pageContext.currentPage;
      props.globalState.updateState({
        [pageKey]: props.pageContext.pageImages,
        cursor: props.pageContext.currentPage + 1,
      });
    }
  }

  render() {
    const g = this.props.globalState;
    const pageContext = this.props.pageContext;
  

    return (
      <>
        {/* Infinite Scroll */}
        <InfiniteScroll
          throttle={150}
          threshold={1800}
          hasMore={g.hasMore(pageContext)}
          onLoadMore={g.loadMore}
        >
          {/* Grid given as a child element for Infinite Scroll. */}
          <section className="services-section">
            <div className="servicesContainer container p-5">
              <Fade cascade distance="15px" left>
                <div className="row mb-3 mt-1 justify-content-start">
                  {Array(1 + g.cursor)
                    .join("#")
                    .split("#")
                    .map((v, idx) => {
                      const key = "page" + idx;
                      return (
                        g[key] &&
                        Array.isArray(g[key]) &&
                        g[key].map(
                          ({ node }, idx) =>
                            console.log(node) || (
                              <div
                                className={`col-md-4 col-sm-12 mb-3 serviceCard`}
                              >
                                {node && (
                                  <PostTile {...node} key={idx + "post"} />
                                )}
                              </div>
                            )
                        )
                      );
                    })}
                </div>
              </Fade>
              {console.log(
                g.isLoading(),
                "g.isLoading()",
                g.hasMore(pageContext)
              )}
              {(g.isLoading() || g.cursor === 0) && g.hasMore(pageContext) && (
                <div className="row mb-3 mt-1 justify-content-start">
                  {Array(1 + pageContext.countImagesPerPage)
                    .join("#")
                    .split("#")
                    .map((v, idx) => (
                      <PostTile {...{
                        content: "A carregar",
                        excerpt: "A carregar, por favor aguarde",
                        featuredImage: {node: {}},
                        id: idx+"fake",
                        slug: "",
                        title: "A Carregar, por favor aguarde"
                        }} key={idx + "post"} />
                    ))}
                </div>
              )}
            </div>
          </section>
        </InfiniteScroll>

        <style jsx>
          {`
            @keyframes spinner {
              to {
                transform: rotate(360deg);
              }
            }
            .spinner {
              margin-top: 40px;
              font-size: 60px;
              text-align: center;
              display: block;
              :global(svg) {
                fill: red;
                animation: spinner 3s linear infinite;
              }
            }
          `}
        </style>
      </>
    );
  }
}

export default BlogInternal;
